
export function useFavorites() {
    const toast = useToast()
    const favorites = useState('favorites', () => [])

    async function fetchFavorites() {
        return useHttpGet('favorite')
            .then((response) => {
                favorites.value = response.data.value
            })
    }

    async function addFavorite(decorId) {
        return useHttpPost('favorite', {
            body: {
                decor_id: decorId,
            }
        }).then((response) => {
            favorites.value = response.data.value
            toast.add({ title: 'Товар добавлен в избранное!' })
        })
    }

    async function removeFavorite(favoriteId) {
        return useHttpDelete('favorite/' + favoriteId)
            .then((response) => {
                favorites.value = response.data.value
                toast.add({ title: 'Товар удален из избранного!' })
            })
    }

    return {
        favorites,
        fetchFavorites,
        addFavorite,
        removeFavorite
    }
}
